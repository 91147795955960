.contact {
    background-color: #FFFFFF;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.5); 
    padding: 80px !important;
    box-sizing: border-box;

    &__thankyou {
        p {
            text-align: center;
            margin-bottom: 50px;
            border: 1px solid;
            padding: 10px;
            font-family: 'Lora';
            font-weight: bold;
            color: green;
        }

        &--error {
            p {
                color: red;
            }
        }
    }

    &.container {
        @media screen and (max-width: 768px) {
            margin: 20px;
            width: 85%;
            box-sizing: border-box;
            padding: 20px !important;
        }
    }

    @media screen and (max-width: 1024px) {
        padding: 30px !important;
        width: 80%;
    }

    &__content {
        display: flex;
        align-items: flex-start;

        @media screen and (max-width: 1024px) {
            display: block;
        }

        img {
            margin: 0 0 20px 0;

            @media screen and (max-width: 768px) {
                margin: 0 auto;
                display: block;
            }
        }

        form {
            margin-left: 130px;
            width: 100%;

            @media screen and (max-width: 1024px) {
                margin-left: 0;
            }

            @media screen and (max-width: 768px) {
                margin: 20px auto;
            }

            input,
            textarea {
                box-sizing: border-box;
                height: 50px;
                max-width: 400px;
                border: 1px solid #DDDDDD;
                border-radius: 5px;
                background-color: #EEEEEE;
                width: 100%;
                padding: 10px;
                font-family: 'Lora';
                font-size: 16px;
                color: #111111;

                &.checkbox-input {
                    width: 30px;
                    height: 30px;
                    margin-right: 15px;
                }

                @media screen and (max-width: 768px) {
                    max-width: none;
                }
            }

            textarea {
                height: 110px;
            }

            label {
                color: #111111;
                font-family: Lora;
                font-size: 16px;
                font-style: italic;
                font-weight: bold;
                letter-spacing: 0.33px;
                line-height: 21px;
                margin-bottom: 10px;
                display: block;

                &.checkbox-label {
                    margin-bottom: 0;
                }

                @media screen and (max-width: 768px) {
                    margin-bottom: 5px;
                    font-size: 14px;
                }
            }

            button {
                box-sizing: border-box;
                height: 50px;
                width: 400px;
                border: 1px solid #DDDDDD;
                border-radius: 5px;
                background-color: #111111;
                cursor: pointer;
                color: #DDDDDD;
                font-family: Lora;
                font-size: 16px;
                font-style: italic;
                font-weight: bold;
                letter-spacing: 0.33px;
                line-height: 21px;
                text-transform: uppercase;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }
            }
        }

        &__input {
            margin-bottom: 30px;

            @media screen and (max-width: 768px) {
                margin-bottom: 20px;
            }
        }

        &__checkbox {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 40px;
        }
    }

    &__separator {
        border: 1px solid #CCCCCC;
        margin: 45px 0 50px;

        @media screen and (max-width: 768px) {
            margin: 30px 0;
        }
    }

    &__social {
        margin-left: 220px;

        @media screen and (max-width: 768px) {
            margin: 0;
        }

        &__item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            text-decoration: none;

            img {
                width: 36px;
                height: 36px;
                margin-right: 20px;
            }

            p {
                color: #111111;
                font-family: Lora;
                font-size: 16px;
                letter-spacing: 0.33px;
                line-height: 19px;
            }
        }
    }
}