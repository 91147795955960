.books {
	min-height: 100vh;
	height: 100%;
	margin-bottom: 100px;

	&__loading {
		position: fixed;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 999;
		justify-content: center;
		align-items: center;
		display: flex;

		.spinner {
			animation: rotate 2s linear infinite;
			z-index: 2;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -25px 0 0 -25px;
			width: 50px;
			height: 50px;

			& .path {
				stroke: rgb(249, 250, 251);
				stroke-linecap: round;
				animation: dash 1.5s ease-in-out infinite;
			}
		}

		@keyframes rotate {
			100% {
				transform: rotate(360deg);
			}
		}

		@keyframes dash {
			0% {
				stroke-dasharray: 1, 150;
				stroke-dashoffset: 0;
			}
			50% {
				stroke-dasharray: 90, 150;
				stroke-dashoffset: -35;
			}
			100% {
				stroke-dasharray: 90, 150;
				stroke-dashoffset: -124;
			}
		}
	}

	&__empty {
		text-align: center;
		font-family: "Bebas Neue";
		font-size: 32px;
		letter-spacing: 1.05px;
		line-height: 25px;
		margin-top: 30px;
	}

	.shelves {
		position: relative;

		&__items {
			max-width: 1090px;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			row-gap: 100px;
			column-gap: 35px;

			@media screen and (max-width: 1130px) {
				column-gap: 0;
			}

			@media screen and (max-width: 768px) {
				grid-template-columns: 1fr 1fr;
				row-gap: 50px;
			}

			&--three {
				grid-template-columns: 1fr 1fr 1fr;
				margin: 50px auto 100px;

				@media screen and (max-width: 768px) {
					margin: 50px auto;
				}

				@media screen and (max-width: 640px) {
					grid-template-columns: 1fr 1fr;
				}
			}

			&__full-center {
				display: inline-block;
				position: relative;
				cursor: pointer;
				z-index: 1;
				justify-self: center;

				@media screen and (max-width: 640px) {
					width: 200%;
					text-align: center;
					display: initial;
					justify-self: normal;

					.shelves__book {
						margin: 0 auto 50px;
					}
				}
			}
		}

		&__heading {
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 30px;
			font-size: 28px;
			font-weight: bold;
		}

		&__book {
			width: 230px;
			height: 360px;
			display: inline-block;
			margin-bottom: 50px;
			position: relative;
			cursor: pointer;
			z-index: 1;
			justify-self: center;

			@media screen and (max-width: 1130px) {
				width: 180px;
				height: 290px;
			}

			@media screen and (max-width: 450px) {
				width: 120px;
				height: 190px;
				margin-right: 0;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}

			a {
				text-decoration: none;
				color: inherit;
			}

			&__shadow {
				background-image: url("../../public/images/home/shadow.png");
				width: 288px;
				height: 425px;
				background-repeat: no-repeat;
				position: absolute;
				background-size: 100% 100%;
				left: -21px;
				top: -33px;

				@media screen and (max-width: 1130px) {
					width: 229px;
					height: 346px;
					top: -29px;
					left: -19px;
				}

				@media screen and (max-width: 450px) {
					left: -12px;
					top: -17px;
					width: 152px;
					height: 223px;
				}
			}

			&__cover {
				width: 100%;
				height: 360px;
				transition: width 0.5s;
				position: absolute;
				z-index: 2;

				@media screen and (max-width: 1130px) {
					height: 290px;
				}

				@media screen and (max-width: 450px) {
					height: 190px;
				}

				&--hovered {
					width: 20px;
				}
			}

			&__page {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				z-index: 1;
				background-color: #fff;
				text-align: center;

				h1 {
					padding: 0 25px;
					font-size: 18px;
					font-weight: bold;
					text-transform: capitalize;
					margin: 20px 0;
					line-height: normal;

					@media screen and (max-width: 450px) {
						font-size: 14px;
						margin: 10px 0;
					}
				}

				p {
					padding: 0 25px;
					line-height: normal;
					font-size: 16px;
					margin: 50px 0;
					text-transform: capitalize;

					@media screen and (max-width: 450px) {
						font-size: 10px;
						margin: 10px 0;
					}
				}

				h2 {
					font-size: 14px;
					font-weight: bold;
					line-height: normal;
					padding: 0 25px;
					position: absolute;
					bottom: 20px;
					display: block;
					text-transform: capitalize;
					width: 100%;
					box-sizing: border-box;

					@media screen and (max-width: 450px) {
						font-size: 11px;
					}
				}

				img {
					margin: 50px 0;
					width: 150px;

					@media screen and (max-width: 450px) {
						width: 70px;
						margin: 10px 0;
					}
				}
			}

			&__black {
				width: 100%;
				height: 100%;
				background-color: #000;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;

				p {
					word-break: break-word;
					color: #ffffff;
					text-transform: uppercase;
					font-family: "Bebas Neue";
					line-height: normal;
					font-size: 42px;
					width: 130px;

					@media screen and (max-width: 450px) {
						font-size: 24px;
						width: 80px;
					}
				}
			}
		}

		&__films {
			background-image: url("../../public/images/home/tsg-films.png");
		}

		&__category {
			width: 303px;
			height: 373px;
			z-index: 2;

			img {
				width: 100%;
				height: 100%;
				margin-bottom: 50px;
				position: relative;
				cursor: pointer;
			}
		}
	}

	&__filter {
		background-image: url("../../public/images/assets/category-frame.png");
		background-size: 100% 100%;
		max-width: 580px;
		height: 130px;
		margin: 50px auto;
		position: relative;

		@media screen and (max-width: 580px) {
			height: 100px;
			margin: 30px auto;
		}

		&__list {
			appearance: none;
			background-color: transparent;
			border: none;
			outline: none;
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;

			&__value {
				top: 0;
				left: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 0;

				p {
					color: #ffffff;
					font-family: "Bebas Neue";
					font-size: 36px;
					letter-spacing: 1.8px;
					line-height: 43px;
					text-align: center;

					@media screen and (max-width: 580px) {
						font-size: 24px;
					}
				}
			}
		}

		img {
			height: 25px;
			margin-left: 25px;
			margin-top: 7px;

			@media screen and (max-width: 580px) {
				margin-left: 10px;
			}
		}
	}

	&__wood {
		width: 100%;
		height: 58px;
		position: absolute;

		&__dark {
			height: 24px;
			width: 100%;
			background-image: url("../../public/images/assets/body-gray.jpg");
		}

		&__light {
			height: 30px;
			width: 100%;
			background-image: url("../../public/images/assets/body-blk.jpg");
		}
	}

	&.home {
		margin: 75px auto 100px;
	}

	@media screen and (max-width: 1130px) {
		.books__wood--home {
			&:first-of-type {
				top: 280px !important;
			}
			&:nth-of-type(2) {
				top: 670px !important;
			}
			&:nth-of-type(3) {
				top: 1110px !important;
			}
			&:nth-of-type(4) {
				top: 1500px !important;
			}
		}
	}

	@media screen and (min-width: 768px) {
		.books__wood--home {
			&:nth-of-type(5),
			&:nth-of-type(6) {
				display: none;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.books__wood--home {
			&:nth-of-type(3) {
				top: 1060px !important;
			}
			&:nth-of-type(4) {
				top: 1450px !important;
			}
			&:nth-of-type(5) {
				top: 1840px !important;
			}
		}
	}

	@media screen and (min-width: 640px) {
		.books__wood--home {
			&:nth-of-type(7),
			&:nth-of-type(8) {
				display: none;
			}
		}
	}

	@media screen and (max-width: 445px) {
		.books__wood {
			&__light,
			&__dark {
				height: 14px;
			}
		}

		.books__wood--home {
			&:first-of-type {
				top: 182px !important;
			}
			&:nth-of-type(2) {
				top: 473px !important;
			}
			&:nth-of-type(3) {
				top: 763px !important;
			}
			&:nth-of-type(4) {
				top: 1053px !important;
			}
			&:nth-of-type(5) {
				top: 1343px !important;
			}
			&:nth-of-type(6) {
				top: 1633px !important;
			}
			&:nth-of-type(7) {
				top: 1923px !important;
			}
			&:nth-of-type(8) {
				top: 2213px !important;
			}
		}
	}
}
