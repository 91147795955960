body {
  margin: 0;
  padding: 0;
  background-image: url('/public/images/assets/body-wht.jpg');
  background-repeat: repeat;
}

.body {
  min-height: 100vh;
}

p {
  color: #000;
  font-size: 18px;
  line-height: 28px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.container {
  max-width: 1000px;
  margin: 70px auto 100px;
  padding: 0 30px;
}

.page-title {
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
  font-size: 52px;
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .container {
    margin: 20px auto 100px;
  }
}
