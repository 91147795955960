.footer {
	padding: 30px;
	background-image: url("../../public/images/assets/black-fabric.jpg");

	&__icons {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		margin-top: 10px;
	}

	&__wrapper {
		text-align: center;
		margin-top: 50px;

		a,
		p {
			color: #fff;
			font-family: "Bebas Neue";
		}
	}

	&__center {
		color: #fff;
		font-style: italic;
		font-family: Lora;
		font-size: 32px;
		text-align: center;
		margin-bottom: 30px;

		@media screen and (max-width: 768px) {
			font-size: 26px;
		}
	}

	&__social {
		display: flex;
		justify-content: space-around;

		@media screen and (max-width: 1024px) {
			flex-direction: column;
		}

		p {
			color: #fff;
			text-align: center;
			font-family: "Lora";
			font-weight: bold;
		}
	}

	&__col2 {
		max-width: 700px;
		margin: 10px auto 30px;
		width: 35%;
		text-align: center;

		@media screen and (max-width: 1024px) {
			width: 100%;
		}

		@media screen and (max-width: 768px) {
			max-width: 400px;
		}

		@media screen and (max-width: 480px) {
			max-width: 300px;
		}

		img {
			width: 50%;
		}
	}

	&__newsletter {
		width: 25%;
		margin: 10px auto 30px;

		@media screen and (max-width: 1024px) {
			width: 100%;
		}

		&__form {
			&__thankyou {
				text-align: center;
				color: limegreen;
				border: 1px solid;
				max-width: 400px;
				width: 100%;
				margin: 15px auto;

				&--error {
					color: orangered;
				}
			}

			input {
				box-sizing: border-box;
				height: 50px;
				max-width: 400px;
				border: 1px solid #dddddd;
				border-radius: 5px;
				background-color: #eeeeee;
				width: 100%;
				padding: 10px;
				font-family: "Lora";
				font-size: 16px;
				color: #111111;
				margin: 15px auto;
				display: block;
			}

			button {
				box-sizing: border-box;
				height: 50px;
				max-width: 400px;
				width: 100%;
				border: 1px solid #dddddd;
				border-radius: 5px;
				background-color: #dddddd;
				cursor: pointer;
				font-family: Lora;
				font-size: 16px;
				font-style: italic;
				font-weight: bold;
				letter-spacing: 0.33px;
				line-height: 21px;
				text-transform: uppercase;
				appearance: none;
				color: #111111;
				display: block;
				margin: 0 auto;
			}
		}
	}
}
