.films {
    @media screen and (max-width: 737px) {
        padding: 0 !important;
    }

    &__title {
        background-image: url("../../public/images/assets/category-frame.png");
        background-size: 100% 100%;
        max-width: 580px;
        height: 130px;
        margin: 50px auto;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1024px) {
            margin: 20px auto;
        }

        @media screen and (max-width: 737px) {
            height: 100px;
        }

        &__content {
            h1 {
                color: #FFFFFF;
                font-family: "Bebas Neue";
                font-size: 36px;
                letter-spacing: 1.8px;
                line-height: 43px;
                text-align: center;
            }
        }
    }

    &__bio {
        width: 83%;
        margin: 50px auto;
        color: #111;
        font-size: 21px;
        font-family: Lora;
        font-weight: bold;

        @media screen and (max-width: 1024px) {
            font-size: 16px;
            width: 79%;
            margin: 0 auto;
            height: 170px;
        }

        @media screen and (max-width: 737px) {
            font-size: 16px;
            width: 90%;
            height: 235px;
            line-height: 18px;
        }
    }

    &__categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        row-gap: 50px;

        @media screen and (max-width: 737px) {
            flex-direction: column;
            align-items: center;
            row-gap: 0px;
        }
        
        &__category {
            background-image: url("../../public/images/films/video-screen.png");
            width: 494px;
            height: 373px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @media screen and (max-width: 1024px) {
                width: 328px;
                height: 248px;
            }

            p {
                color: #fff;
                text-transform: uppercase;
                font-size: 28px;
                line-height: 28px;
                position: absolute;
                max-width: 270px;
                text-align: center;
            }

            &__prev {
                width: 80%;
            }
        }

        .books__wood {
            z-index: -1;
        }

        .books__wood--category {
            &:nth-of-type(3),
            &:nth-of-type(4) {
                display: none;
            }
        }
    }

    &__description {
        margin: 50px auto 0;
        max-width: 800px;

        h2 {
            font-size: 28px;
            margin-bottom: 15px;
            line-height: 28px;
            color: #fff;
            font-weight: bold;
        }

        p {
            color: #fff;
        }
    }

    &__videos {
        position: relative;
        
        &__screens {
            display: grid;
            position: relative;
            margin-bottom: 100px;
            grid-template-columns: 1fr 1fr;
            max-width: 1000px;
            margin: 0 auto 100px;        

            @media screen and (max-width: 737px) {
                flex-direction: column;
                grid-template-columns: none;
            }
        }

        &__video {
            background-image: url("../../public/images/films/video-screen.png");
            width: 494px;
            height: 373px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            z-index: 2;
            justify-self: center;

            @media screen and (max-width: 1024px) {
                width: 328px;
                height: 248px;
            }

            img {
                position: absolute;
                width: 85%;
                height: 58%;
                top: 70px;
                left: 30px;

                @media screen and (max-width: 1024px) {
                    width: 85%;
                    height: 57%;
                    top: 45px;
                    left: 17px;
                }
            }

            &__title {
                margin: 0;
                position: absolute;
                font-size: 24px;
                z-index: 2;
                color: #fff;
                text-transform: uppercase;
                max-width: 250px;
                text-align: center;
                font-weight: bold;
            }

            &__artist {
                margin: 0;
                position: absolute;
                font-size: 12px;
                z-index: 2;
                color: #fff;
                max-width: 150px;
                text-align: center;
                line-height: normal;
                margin-top: 60px;
            }

            &__details {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .books__wood--category {
            &:first-of-type {
                top: 663px !important;
            }

            &:nth-of-type(2) {
                top: 960px !important;
            }
        }     
    }

    @media screen and (max-width: 737px) {
        .books__wood {
            height: auto;

            &__dark,
            &__light {
                height: 14px;
            }
        }

        .books__wood--category {
            &:first-of-type {
                top: 698px !important;
            }

            &:nth-of-type(2) {
                top: 946px !important;
            }

            &:nth-of-type(3) {
                display: block;
                top: 1195px !important;
            }

            &:nth-of-type(4) {
                display: block;
                top: 1444px !important;
            }
        }     
    }
}