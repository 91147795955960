.makers {
    max-width: 1000px;
    margin: 70px auto 100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        margin: 20px;
    }

    @media screen and (max-width: 768px) {
        display: block;
    }

    &__title {
        background-image: url("../../public/images/assets/category-frame.png");
        background-size: 100% 100%;
        max-width: 580px;
        height: 130px;
        margin: 50px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;

        @media screen and (max-width: 425px) {
            margin: 20px auto;
            height: 100px;
        }

        &__content {
            p {
                color: #FFFFFF;
                font-family: "Bebas Neue";
                font-size: 36px;
                letter-spacing: 1.8px;
                line-height: 43px;
                text-align: center;

                @media screen and (max-width: 425px) {
                    font-size: 32px;
                }
            }
        }
    }

    &__person {
        height: 158px;
        width: 325px;
        background-color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);

        @media screen and (max-width: 1024px) {
            width: 100%;
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }

        @media screen and (max-width: 425px) {
            height: 120px;
        }
        
        &__small {
            display: flex;
            flex-direction: row;
            padding: 10px;
            align-items: flex-start;
            box-sizing: border-box;
            cursor: pointer;

            img {
                width: 138px;
                height: 138px;
                margin-right: 15px;

                @media screen and (max-width: 425px) {
                    width: 100px;
                    height: 100px;
                }
            }
        }

        &__card {
            display: none;

            &--show {
                display: flex;
                align-items: center;
                justify-content: center;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,0.5);
                z-index: 9999;

                img {
                    width: 138px;
                    height: 138px;
                    margin: 0 auto;
                    display: block;

                    @media screen and (max-width: 768px) {
                        width: 100px;
                        height: 100px;
                        margin-top: 20px;
                    }
                }
            }

            &__content {
                max-width: 1000px;
                width: 80%;
                background-color: #FFFFFF;
                box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);       
                position: relative;
                padding: 80px;
                box-sizing: border-box;

                @media screen and (max-width: 1024px) {
                    padding: 60px 40px 40px 40px;
                    padding: 50px 20px 20px 20px;
                    max-height: 80%;
                    overflow: scroll;
                }
            }

            &__close {
                position: absolute;
                top: 30px;
                right: 35px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1024px) {
                    top: 20px;
                    right: 20px;
                }

                img {
                    width: 18px;
                    height: 18px;
                    margin: 0;
                    cursor: pointer;
                    margin: 0 14px;
                }
            }
        }

        &__details {
            width: 100%;

            &__name {
                color: #111111;
                font-family: Lora;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.38px;
                line-height: 24px;

                h1 {
                    display: inline-block;
                }
            }
            
            &__type {
                color: #111111;
                font-family: Lora;
                font-size: 16px;
                letter-spacing: 0.67px;
                line-height: 19px;
            }

            &__separator {
                border: 1px solid #CCCCCC;
                width: 100%;
                margin: 20px 0 10px;

                @media screen and (max-width: 425px) {
                    margin: 5px 0 10px;
                }
            }

            p {
                font-size: 16px;
                line-height: 24px;
                color: #000;
            }

            &--centered {
                text-align: center;
                margin: 20px 0 40px;

                @media screen and (max-width: 768px) {
                    margin: 10px 0 20px;
                }
            }

            &--centered & {
                &__separator {
                    max-width: 139px;
                    margin: 10px auto;
                }
            }
        }

        &__content {
            p {
                color: #111111;
                font-family: Lora;
                font-size: 16px;
                letter-spacing: 0.8px;
                line-height: 28px;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
    }
}