.search {
	text-align: center;
	margin: 10px 0 20px;

	input {
		appearance: none;
		background: transparent;
		border: none;
		border-bottom: 2px solid #777;
		min-width: 250px;
		font-family: "Bebas Neue";
		font-size: 21px;
		color: #fff;
		outline: none;
	}

	img {
		width: 30px;
		height: 30px;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
		cursor: pointer;
	}
}
