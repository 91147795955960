.book {
    max-width: 1000px;
    width: 80%;
    min-height: 100vh;
    height: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 70px 80px;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
        width: 90%;
        padding: 40px;
    }

    &__heading {
        display: flex;
        align-items: flex-start;
        margin-bottom: 50px;

        @media screen and (max-width: 768px) {
            flex-direction: column-reverse;
            margin-bottom: 0;
        }

        &__divider {
            height: 2px;
            width: 100%;
            background: #ccc;
            margin: 10px 0;
        }

        &__cover {
            width: 300px;
            height: 450px;
            margin-right: 40px;

            @media screen and (max-width: 1024px) {
                width: 200px;
                height: 300px;
                margin-right: 20px;
            }

            @media screen and (max-width: 768px) {
                margin: 30px auto;
            }
        }

        h1 {
            color: #111111;
            font-family: Lora;
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 52px;

            @media screen and (max-width: 1024px) {
                font-size: 34px;
                line-height: 40px;
            }

            @media screen and (max-width: 768px) {
                font-size: 24px;
                line-height: normal;
            }
        }

        h2 {
            color: #111111;
            font-family: Lora;
            font-size: 24px;
            letter-spacing: 1px;
            line-height: 29px;
            margin-bottom: 30px;

            @media screen and (max-width: 1024px) {
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 20px;
            }
        }

        &__data {
            width: 100%;
        }

        &__links {
            h3 {
                color: #777777;
                font-family: Lora;
                font-size: 16px;
                letter-spacing: 0.67px;
                line-height: 19px;
                margin-top: 20px;

                @media screen and (max-width: 1024px) {
                    margin-top: 10px;
                }
            }

            a {
                margin-top: 10px;
                margin-right: 4px;
                display: inline-block;
                text-decoration: none;
            }

            &__item {
                color: #FFFFFF;
                font-family: Lora;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.75px;
                line-height: 28px;
                text-align: center;
                background: #000;
                width: fit-content;
                padding: 2px 13px;
                border-radius: 5px;
                text-transform: capitalize;
            }
        }
    }

    &__content {
        font-size: 17px;
        color: #000;
        line-height: 24px;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }

        p {
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 26px;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 20px;
            line-height: 1.4;
        }

        h2 {
            font-size: 26px;

            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }

        h2 {
            font-size: 24px;

            @media screen and (max-width: 768px) {
                font-size: 22px;
            }
        }

        h3 {
            font-size: 22px;

            @media screen and (max-width: 768px) {
                font-size: 20px;
            }
        }

        h4 {
            font-size: 20px;

            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        h5 {
            font-size: 18px;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        h6 {
            font-size: 16px;

            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
}