.news {
    &.container {
        @media screen and (max-width: 768px) {
            padding: 0 10px;
        }
    }

    &__title {
        background-image: url("../../public/images/assets/category-frame.png");
        background-size: 100% 100%;
        max-width: 580px;
        height: 130px;
        margin: 50px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;

        @media screen and (max-width: 768px) {
            margin: 20px auto;
            height: 100px;
        }

        &__content {
            p {
                color: #FFFFFF;
                font-family: "Bebas Neue";
                font-size: 36px;
                letter-spacing: 1.8px;
                line-height: 43px;
                text-align: center;
            }

            &--arrows {
                display: flex;
                align-items: center;
                width: 80%;
                justify-content: space-between;

                img {
                    width: 27px;
                    height: 27px;
                    cursor: pointer;
                }
            }
        }
    }

    &__article {
        background-color: #FFFFFF;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
        padding: 50px 80px 40px;
        margin-bottom: 10px;

        @media screen and (max-width: 768px) {
            padding: 20px;
        }

        h1 {
            color: #111111;
            font-family: Lora;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 36px;

            @media screen and (max-width: 768px) {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &__separator {
            border: 1px solid #CCCCCC;
            margin: 20px 0;

            @media screen and (max-width: 768px) {
                margin: 10px 0;
            }
        }

        &__details {
            display: flex;
            justify-content: space-between;

            p {
                color: #111111;
                font-family: Lora;
                font-size: 18px;
                letter-spacing: 0.75px;
                line-height: 22px;

                @media screen and (max-width: 768px) {
                    font-size: 16px;
                }
            }

            a {
                color: inherit;
                text-decoration: none;
            }

            &__more {
                cursor: pointer;
            }
        }

        &--single {
            margin-top: -135px;
            padding-top: 150px;
            padding-bottom: 90px;

            @media screen and (max-width: 768px) {
                margin-top: -85px;
                padding-top: 80px;
                padding-bottom: 50px;
            }
        }

        &--single & {
            &__details {
                justify-content: flex-end;
                margin-bottom: 45px;
            } 
        }

        &__content {
            color: #111111;
            font-family: Lora;
            font-size: 16px;
            letter-spacing: 0.8px;
            line-height: 28px;

            p {
                margin-bottom: 20px;
            }
        }

        &__images {
            img {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}