.adiuva {
    &.container {
        @media screen and (max-width: 768px) {
            padding: 0 10px;
        }
    }

    &__title {
        background-image: url("../../public/images/assets/category-frame.png");
        background-size: 100% 100%;
        max-width: 580px;
        height: 130px;
        margin: 50px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;

        @media screen and (max-width: 768px) {
            height: 100px;
            margin: 20px auto;
            width: 85%;
        }

        &__content {
            p {
                color: #FFFFFF;
                font-family: "Bebas Neue";
                font-size: 36px;
                letter-spacing: 1.8px;
                line-height: 43px;
                text-align: center;
            }
        }
    }

    &__main {
        max-width: 1000px;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        margin: 0 auto;
        background-color: #fff;
        padding: 121px 80px 50px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
        margin-top: -130px;
        z-index: 1;
        color: #111111;
        font-family: Lora;
        box-sizing: border-box;

        @media screen and (max-width: 768px) {
            margin-top: -80px;
            padding: 70px 20px 50px;
        }

        &__subtitle {
            font-size: 48px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 52px;
            margin-bottom: 30px;

            @media screen and (max-width: 768px) {
                font-size: 32px;
                line-height: 36px;
            }
        }

        &__quote {
            margin-bottom: 45px;

            p {
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 1.2px;
                line-height: 32px;

                @media screen and (max-width: 768px) {
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 25px;
                }
            }

            &__name {
                p {
                    font-size: 16px;
                    letter-spacing: 0.8px;
                    line-height: 32px;
                    text-align: right;
                    font-weight: normal;

                    @media screen and (max-width: 768px) {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }

        &__content {
            &__paragraph {
                font-size: 16px;
                letter-spacing: 0.8px;
                line-height: 28px;
                margin-bottom: 30px;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
            }
        }

        &__images {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 45px;

            @media screen and (max-width: 768px) {
                display: block;
                margin-bottom: 25px;
            }

            &__item {
                max-width: 410px;
                width: 48%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    max-width: none;
                }
            }
        }

        &__signature {
            margin-bottom: 20px;
        }
    }

}