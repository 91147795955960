.about {
    &.container {
        @media screen and (max-width: 445px) {
            padding: 0 10px;
        }
    }

    &__title {
        background-image: url('../../public/images/assets/category-frame.png');
        background-size: 100% 100%;
        width: 580px;
        height: 130px;
        margin: 50px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        position: relative;

        @media screen and (max-width: 768px) {
            width: 350px;
            height: 100px;
        }

        @media screen and (max-width: 445px) {
            width: 280px;
            margin-top: 0px;
        }

        &__content {
            p {
                color: #FFFFFF;
                font-family: "Bebas Neue";
                font-size: 36px;
                letter-spacing: 1.8px;
                line-height: 43px;
                text-align: center;

                @media screen and (max-width: 768px) {
                    font-size: 28px;
                }
            }
        }
    }

    &__content {
        max-width: 1000px;
        width: 100%;
        min-height: 100vh;
        height: 100%;
        margin: 0 auto;
        background-color: #fff;
        padding: 144px 80px 50px;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.5);
        margin-top: -130px;
        z-index: 1;
        box-sizing: border-box;

        @media screen and (max-width: 768px) {
            margin-top: -115px;
            padding: 100px 50px 30px;
            width: 100%;
        }

        @media screen and (max-width: 445px) {
            padding: 80px 30px 30px;
        }

        &__paragraph {
            p {
                margin-bottom: 30px; 
                color: #111111;
                font-family: Lora;
                font-size: 18px;
                letter-spacing: 0.8px;
                line-height: 28px; 
                
                @media screen and (max-width: 768px ) {
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 20px;
                }
            }     
        }
    }
}
