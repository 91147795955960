.header {
	background-image: url("../../public/images/assets/black-fabric.jpg");
	background-repeat: repeat;
	position: sticky;
	top: 0;
	z-index: 99;

	&:after {
		content: "";
		background-image: url("../../public/images/assets/body-blk.jpg");
		width: 100%;
		height: 20px;
		display: block;
		box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
	}

	&--big {
		&:after {
			@media screen and (max-width: 1024px) {
				content: "Harnessing the Means of Production";
				color: #fff;
				text-align: center;
				font-weight: bold;
				height: 35px;
			}
		}
	}

	&__wrapper {
		padding: 20px 0 15px 0;
		height: 110px;
		box-sizing: border-box;
		transition: height 0.5s;

		@media screen and (max-width: 1024px) {
			height: 80px;
			padding: 10px 50px;
		}

		@media screen and (max-width: 1024px) {
			padding: 10px 30px;
		}

		&__menu-icon {
			width: 30px;
			height: 30px;
			position: absolute;
			top: 15px;
			right: 0;

			img {
				width: 100%;
			}
		}

		&__desktop {
			display: flex;
			align-items: center;
			justify-content: center;

			@media screen and (max-width: 1024px) {
				display: none;
			}
		}

		&__mobile {
			position: relative;

			@media screen and (min-width: 1025px) {
				display: none;
			}

			&__logo {
				@media screen and (max-width: 1025px) {
					width: 100%;
					text-align: center;
				}
			}

			&__menu {
				display: flex;
				flex-direction: column;
				text-align: center;
				padding: 20px 50px;
				background-image: url("../../public/images/assets/black-fabric.jpg");
				box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
				position: fixed;
				top: 165px;
				z-index: 3;
				width: 100%;
				box-sizing: border-box;

				a {
					color: #ffffff;
					text-decoration: none;
				}

				&--big {
					top: 220px;
				}
			}
		}

		a {
			color: #777777;
			text-decoration: none;

			&:hover {
				color: #fff;
			}
		}

		&__text {
			height: 25px;
			width: 60px;
			font-family: "Bebas Neue";
			font-size: 21px;
			letter-spacing: 1.05px;
			line-height: 25px;
			text-align: center;
			text-decoration: none;
			cursor: pointer;
			transition: font-size 0.1s;
			padding-right: 18px;

			&--mobile {
				padding-right: 0;
				font-size: 24px;
				height: auto;
				line-height: 40px;
			}
		}

		&__logo {
			height: 75px;
			width: 70px;
			margin: 0 63px;

			@media screen and (max-width: 1024px) {
				height: 56px;
				text-align: center;
				display: inline-block;
				margin: 0;
			}

			img {
				width: 100%;
				height: 100%;

				@media screen and (max-width: 1024px) {
					width: 50px;
				}
			}
		}

		&--big {
			box-sizing: initial;
			height: 220px;

			@media screen and (max-width: 1248px) {
				height: 160px;
			}

			@media screen and (max-width: 1024px) {
				height: 100px;
			}
		}

		&--big & {
			&__logo {
				height: 200px;
				width: 190px;

				@media screen and (max-width: 1248px) {
					height: 150px;
					width: 140px;
					margin: 0 30px;
				}

				@media screen and (max-width: 1024px) {
					height: 100px;
					text-align: center;
					margin: 0;

					img {
						width: 90px;
					}
				}
			}

			&__menu-icon {
				top: 35px;
			}

			&__text {
				font-size: 24px;

				@media screen and (max-width: 1248px) {
					font-size: 21px;
				}
			}
		}
	}
}
